import { DbDocument } from 'src/dto/dbDocuments/DdDocument';
import { DOCUMENTS_PER_PAGE } from '../constants';
import {
  Button,
  HeadingXSmall,
  LabelSmall,
  NoContent,
  ReportingIcon,
  Wrapper,
  useStyletron,
} from '@visualfabriq/vf-ui-kit';

import { documentsListTestId } from './constants';
import { List, ListHeader, ListItem, ListPagination } from 'src/components/organisms/List';
import { Resizer } from 'src/components/atoms/Resizer';
import { useResize } from 'src/components/hooks/useResize';

type Props = {
  documents: DbDocument[] | undefined;
  loading: boolean;
  skipDocuments: number;
  docCount: number | undefined;
  onNext: () => void;
  onPrev: () => void;
  onRefresh: () => void;
  onExport: () => void;
};

export function DocumentsList(props: Props) {
  const { documents, skipDocuments, loading, docCount, onNext, onPrev, onRefresh, onExport } = props;
  const [, theme] = useStyletron();
  const [resizeRef, width] = useResize<HTMLDivElement>({ initialWidth: 250, minWidth: 250, maxWidths: 500 });

  return (
    <Wrapper marginInline={`0 ${theme.sizing.scale800}`} data-testid={documentsListTestId}>
      <div style={{ width }}>
        <List
          heading={
            <Wrapper marginBlock={`0 ${theme.sizing.scale800}`}>
              <ListHeader onRefresh={onRefresh}>
                <Wrapper gap={200} alignItems="center">
                  <HeadingXSmall marginBottom="0">Documents</HeadingXSmall>
                  {docCount && (
                    <LabelSmall marginBottom="0" kind="tertiary">
                      ({docCount})
                    </LabelSmall>
                  )}
                  <Button kind="secondary" startEnhancer={ReportingIcon} onClick={onExport} size="mini">
                    Export
                  </Button>
                </Wrapper>
              </ListHeader>
            </Wrapper>
          }
          loading={loading}
          style={{ overflow: 'auto', height: 'calc(100% - 42px)', width: '100%' }}
          loadingItems={DOCUMENTS_PER_PAGE}
        >
          {documents?.length ? (
            documents.map(({ _id }) => <ListItem key={_id} item={{ id: _id, name: _id }} />)
          ) : (
            <NoContent message="No documents to show" />
          )}
        </List>

        {documents?.length && (
          <ListPagination
            size={DOCUMENTS_PER_PAGE}
            currentPageItemsCount={documents.length}
            skip={skipDocuments}
            onPrev={onPrev}
            onNext={onNext}
          />
        )}
      </div>
      <Resizer ref={resizeRef} />
    </Wrapper>
  );
}
