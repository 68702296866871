import { DURATION, Wrapper, styled, useSnackbar, useStyletron } from '@visualfabriq/vf-ui-kit';
import { Outlet, useParams } from 'react-router-dom';
import { useContext, useMemo, useState } from 'react';

import { DocumentsFilter } from './DocumentsFilter';
import { DbDocumentsApi, DocumentFilter } from 'src/api-new/bifrost';
import { DOCUMENTS_PER_PAGE } from './constants';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { useInstances } from 'src/components/hooks/useInstances';
import { DocumentsList } from './DocumentsList';
import { useAsync, useAsyncRetry } from 'react-use';
import { DocumentsExportService } from 'src/services/DocumentsExportService';
import { ErrorGeneral } from 'src/components/ErrorGeneral';
import { InsightsContext } from 'src/domain/insights';
import { DocumentFiltersOperatorUIOnly, FiltersOperator } from 'src/domain/insights/types';
import { captureException } from 'src/services/sentry';

export function Collection() {
  const { collectionName } = useParams();
  const { selectedInstance } = useInstances();
  const dbDocumentsApi = useBifrostApi(DbDocumentsApi);
  const snackbar = useSnackbar();
  const [css] = useStyletron();
  const documentsExportService = useMemo(() => new DocumentsExportService(dbDocumentsApi), [dbDocumentsApi]);
  const { filters, setFilters, filtersOperator, setFiltersOperator } = useContext(InsightsContext);

  const [skipDocuments, setSkipDocuments] = useState(0);

  const documentsState = useAsyncRetry(fetchDocuments, [
    skipDocuments,
    collectionName,
    selectedInstance.id,
    filters,
    filtersOperator,
  ]);

  const { value: docCount } = useAsync(async () => {
    if (!collectionName) return;

    try {
      const { data } = await dbDocumentsApi.getDocumentsCount({
        documentsQueryBase: {
          instance_id: selectedInstance.id,
          collection_name: collectionName,
          filters,
          ...(filtersOperator !== DocumentFiltersOperatorUIOnly.None ? { filters_operator: filtersOperator } : {}),
        },
      });
      return data.documents_count;
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(`Failed to load documents count for ${collectionName}`);
      return undefined;
    }
  }, [selectedInstance.id, collectionName, filters]);

  const handleFilterChange = (documentFilters: DocumentFilter[], filtersOperator: FiltersOperator) => {
    setSkipDocuments(0);
    setFilters(documentFilters);
    setFiltersOperator(filtersOperator);
  };

  async function fetchDocuments() {
    try {
      const { data } = await dbDocumentsApi.getDocuments({
        documentsQueryPagination: {
          instance_id: selectedInstance.id,
          collection_name: collectionName!,
          filters,
          ...(filtersOperator !== DocumentFiltersOperatorUIOnly.None ? { filters_operator: filtersOperator } : {}),
          limit: DOCUMENTS_PER_PAGE,
          skip: skipDocuments,
        },
      });
      return data;
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(`Failed to load documents for ${collectionName}`);
    }
  }

  const handleNextPageClick = async () => {
    setSkipDocuments(skipDocuments + DOCUMENTS_PER_PAGE);
  };

  const handlePrevPageClick = async () => {
    setSkipDocuments(skipDocuments - DOCUMENTS_PER_PAGE);
  };

  const handleCollectionExport = async () => {
    if (!collectionName) return;

    try {
      snackbar.enqueue({ message: 'Exporting... Please wait', progress: true }, DURATION.infinite);
      documentsExportService.export({
        instanceId: selectedInstance.id,
        collectionName: collectionName,
        filterOperator: filtersOperator === DocumentFiltersOperatorUIOnly.None ? undefined : filtersOperator,
        filters,
        onProgress: ({ message, type }) => {
          const isProgress = type === 'progress';
          snackbar.dequeue();
          snackbar.enqueue({ message, progress: isProgress }, isProgress ? DURATION.infinite : DURATION.short);
        },
      });
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(error.message);
    }
  };

  if (documentsState.error) {
    return <ErrorGeneral title="Something went wrong" description={documentsState.error.message} />;
  }

  return (
    <Wrapper direction="column" className={css({ overflow: 'hidden' })}>
      <Actions>
        <DocumentsFilter documentFilters={filters} filtersOperator={filtersOperator} onChange={handleFilterChange} />
      </Actions>

      <DocumentsContent>
        <DocumentsList
          documents={documentsState.value}
          loading={documentsState.loading}
          skipDocuments={skipDocuments}
          docCount={docCount}
          onNext={handleNextPageClick}
          onPrev={handlePrevPageClick}
          onRefresh={documentsState.retry}
          onExport={handleCollectionExport}
        />

        <Outlet />
      </DocumentsContent>
    </Wrapper>
  );
}

const actionsHeight = '50px';
const Actions = styled('div', () => ({
  height: actionsHeight,
}));

const DocumentsContent = styled('div', () => ({
  display: 'flex',
  height: `calc(100% - ${actionsHeight})`,
}));
